import React from 'react';
import {Image, Pressable, View} from 'react-native';
import {Card, Subheading, Title, useTheme} from 'react-native-paper';
import getStyles from '../styles/styles';
import {Charger} from '../ts/types';
import Plug from './Plug';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {Theme} from '../styles/colors';

const AppFooter: React.FC = () => {
  const theme = useTheme() as Theme;
  const {colors} = theme;
  const styles = getStyles(theme);

  return (
    <View style={styles.appfooterContainer}>
      <Card style={styles.appfooterCard} elevation={0}>
        <Pressable
          onPress={() => {}}
          android_ripple={{borderless: true}}
          style={styles.plugCardRipple}>
          <View style={styles.appfooterCardContainer}>
            <Icon
              name="plus-circle-outline"
              size={50}
              color={colors.disabled}
            />
            <Title style={styles.appfooterTitle}>Add a charger</Title>
          </View>
        </Pressable>
      </Card>
    </View>
  );
};

export default AppFooter;
