import React from 'react';
import {View} from 'react-native';
import {
  Card,
  Subheading,
  Title,
  Text,
  Button,
  useTheme,
  IconButton,
  TextInput,
} from 'react-native-paper';
import getStyles from '../styles/styles';
import {Charger, Plug, ModalProps} from '../ts/types';
import Modal from './Modal';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {Theme} from '../styles/colors';

type Props = ModalProps & {
  charger: Charger | null;
};

const EditModal: React.FC<Props> = ({visible, setVisible, charger}) => {
  const theme = useTheme() as Theme;
  const {colors} = theme;
  const styles = getStyles(theme);

  return (
    <Modal visible={visible} setVisible={setVisible}>
      {charger ? (
        <View style={styles.modalContainer}>
          <View style={styles.infoModalContent}>
            <Subheading>{charger.model}</Subheading>
          </View>
          <TextInput mode="outlined" label="Name" />
          <View style={styles.editModalActions}>
            <Button
              mode="outlined"
              icon="close"
              color={colors.buttonRed}
              style={[styles.modalButton, {borderColor: colors.buttonRed}]}
              onPress={() => {}}>
              Delete
            </Button>
            <Button
              mode="outlined"
              icon="check"
              color={colors.buttonGreen}
              style={[styles.modalButton, {borderColor: colors.buttonGreen}]}
              onPress={() => {}}>
              Done
            </Button>
          </View>
        </View>
      ) : (
        <Text>Error</Text>
      )}
    </Modal>
  );
};

export default EditModal;
