import {DarkTheme, DefaultTheme} from 'react-native-paper';

type Theme = typeof DefaultTheme & {
  colors: {
    primary: string;
    secondary: string;
    accent: string;
    chargingNone: string;
    chargingProgress: string;
    chargingSuccess: string;
    chargingError: string;
    chargingStopped: string;
    chargingNoneBackground: string;
    chargingProgressBackground: string;
    chargingSuccessBackground: string;
    chargingErrorBackground: string;
    chargingStoppedBackground: string;
    buttonRed: string;
    buttonOrange: string;
    buttonGreen: string;
  };
};

type ThemeNames = 'light' | 'dark';
type Themes = {
  [name in ThemeNames]: {
    name: string;
    value: ThemeNames;
    theme: Theme;
    statusbar: 'light-content' | 'dark-content';
  };
};

const themes: Themes = {
  light: {
    name: 'Clair',
    value: 'light',
    statusbar: 'dark-content',
    theme: {
      ...DefaultTheme,
      colors: {
        ...DefaultTheme.colors,
        primary: '#1155cc',
        secondary: '#bbdefb',
        chargingNone: '#616161',
        chargingProgress: '#1155cc',
        chargingSuccess: '#6aa84f',
        chargingError: '#B71C1C',
        chargingStopped: '#F57F17',
        chargingNoneBackground: '#E0E0E0',
        chargingProgressBackground: '#bbdefb',
        chargingSuccessBackground: '#DCEDC8',
        chargingErrorBackground: '#EF9A9A',
        chargingStoppedBackground: '#FFF9C4',
        buttonGreen: '#6aa84f',
        buttonOrange: '#F57F17',
        buttonRed: '#B71C1C',
      },
    },
  },
  dark: {
    name: 'Sombre',
    value: 'dark',
    statusbar: 'light-content',
    theme: {
      ...DarkTheme,
      colors: {
        ...DarkTheme.colors,
        background: '#212121',
        primary: '#bbdefb',
        secondary: '#1155cc',
        accent: '#6aa84f',
        chargingNone: '#757575',
        chargingProgress: '#598edd',
        chargingSuccess: '#6aa84f',
        chargingError: '#ff0000',
        chargingStopped: '#F57F17',
        chargingNoneBackground: '#424242',
        chargingProgressBackground: '#002863',
        chargingSuccessBackground: '#003a03',
        chargingErrorBackground: '#630000',
        chargingStoppedBackground: '#6d5300',
        buttonGreen: '#6aa84f',
        buttonOrange: '#F57F17',
        buttonRed: '#ff0000',
      },
    },
  },
};

export type {Theme, ThemeNames, Themes};
export default themes;
