import {Charger} from '../ts/types';

const chargers: Charger[] = [
  {
    id: 1,
    name: 'Charger 1',
    model: 'NomadEV 2.3',
    power: 23,
    phases: 3,
    plugs: [
      {
        id: 1,
        type: 'a',
        status: 'charging',
        power: 20,
        progress: 73,
      },
      {
        id: 2,
        type: 'a',
        status: 'none',
        power: null,
        progress: null,
      },
      {
        id: 3,
        type: 'a',
        status: 'full',
        power: 0,
        progress: 100,
      },
    ],
  },
  {
    id: 2,
    name: 'Charger 2',
    model: 'NomadEV 2.1',
    power: 72,
    phases: 1,
    plugs: [
      {
        id: 1,
        type: 'a',
        status: 'error',
        power: 0,
        progress: 73,
      },
      {
        id: 2,
        type: 'b',
        status: 'stopped',
        power: 0,
        progress: 34,
      },
      {
        id: 3,
        type: 'b',
        status: 'charging',
        power: 15,
        progress: 93,
      },
    ],
  },
  {
    id: 3,
    name: 'Charger 3',
    model: 'NomadEV 1.1',
    power: 20,
    phases: 3,
    plugs: [
      {
        id: 1,
        type: 'a',
        status: 'full',
        power: 0,
        progress: 300,
      },
      {
        id: 2,
        type: 'b',
        status: 'full',
        power: 0,
        progress: 300,
      },
      {
        id: 3,
        type: 'b',
        status: 'charging',
        power: 20,
        progress: 93,
      },
    ],
  },
  {
    id: 4,
    name: 'Charger 4',
    model: 'NomadEV 1.1',
    power: 20,
    phases: 3,
    plugs: [
      {
        id: 1,
        type: 'a',
        status: 'none',
        power: null,
        progress: null,
      },
      {
        id: 2,
        type: 'b',
        status: 'error',
        power: 0,
        progress: 20,
      },
      {
        id: 3,
        type: 'b',
        status: 'charging',
        power: 20,
        progress: 93,
      },
    ],
  },
];

export default chargers;
