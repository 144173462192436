import React from 'react';
import {Pressable, View} from 'react-native';
import {Card, Subheading, Text, Title, useTheme} from 'react-native-paper';
import {Theme} from '../styles/colors';
import getStyles from '../styles/styles';
import {Charger, Plug} from '../ts/types';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

type Props = {
  plug: Plug;
  charger: Charger;
  select: (c: number, p: number) => void;
};

const PlugCard: React.FC<Props> = ({plug, charger, select}) => {
  const theme = useTheme() as Theme;
  const {colors} = theme;
  const styles = getStyles(theme);

  const backgroundColors = {
    charging: colors.chargingProgressBackground,
    full: colors.chargingSuccessBackground,
    error: colors.chargingErrorBackground,
    stopped: colors.chargingStoppedBackground,
    none: colors.chargingNoneBackground,
  };
  const textColors = {
    charging: colors.chargingProgress,
    full: colors.chargingSuccess,
    error: colors.chargingError,
    stopped: colors.chargingStopped,
    none: colors.chargingNone,
  };

  const plugTypes = {
    a: 'power-plug',
    b: 'power-plug',
    c: 'power-plug',
  };

  const plugStatus = {
    charging: 'Charging',
    full: 'Full',
    error: 'ERROR',
    stopped: 'Stopped',
    none: '',
  };

  return (
    <View style={styles.plugContainer}>
      <Card
        elevation={0}
        style={[
          styles.plugCard,
          {
            backgroundColor: backgroundColors[plug.status],
          },
        ]}>
        <Pressable
          onPress={() => select(charger.id, plug.id)}
          android_ripple={{
            borderless: true,
            color: textColors[plug.status],
          }}
          style={styles.plugCardRipple}>
          <View style={styles.plugCardBody}>
            <Icon
              name={plugTypes[plug.type]}
              color={textColors[plug.status]}
              size={32}
            />
            <Subheading
              style={[styles.plugStatusText, {color: textColors[plug.status]}]}>
              {plugStatus[plug.status]}
            </Subheading>
            {plug.power !== null ? (
              <Text
                style={[
                  styles.plugPowerText,
                  {color: textColors[plug.status]},
                ]}>
                {plug.power} kW
              </Text>
            ) : null}
            {plug.progress !== null ? (
              <Text
                style={[
                  styles.plugProgressText,
                  {color: textColors[plug.status]},
                ]}>
                {plug.progress} kW.h
              </Text>
            ) : null}
          </View>
        </Pressable>
      </Card>
    </View>
  );
};

export default PlugCard;
