import React from 'react';
import {View} from 'react-native';
import {
  Card,
  Subheading,
  Title,
  Text,
  Button,
  useTheme,
  IconButton,
} from 'react-native-paper';
import getStyles from '../styles/styles';
import {Charger, Plug, ModalProps} from '../ts/types';
import Modal from './Modal';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {Theme} from '../styles/colors';

type Props = ModalProps & {
  charger: Charger | null;
  plug: Plug | null;
};

const InfoModal: React.FC<Props> = ({visible, setVisible, plug, charger}) => {
  const theme = useTheme() as Theme;
  const {colors} = theme;
  const styles = getStyles(theme);

  const backgroundColors = {
    charging: colors.chargingProgressBackground,
    full: colors.chargingSuccessBackground,
    error: colors.chargingErrorBackground,
    stopped: colors.chargingStoppedBackground,
    none: colors.chargingNoneBackground,
  };
  const textColors = {
    charging: colors.chargingProgress,
    full: colors.chargingSuccess,
    error: colors.chargingError,
    stopped: colors.chargingStopped,
    none: colors.chargingNone,
  };

  const plugTypeIcons = {
    a: 'power-plug',
    b: 'power-plug',
    c: 'power-plug',
  };
  const plugTypeDescriptions = {
    a: 'Type 1',
    b: 'Type 2',
    c: 'Type 2a',
  };

  const plugStatus = {
    charging: 'Charging',
    full: 'Full',
    error: 'ERROR',
    stopped: 'Stopped',
    none: 'Not connected',
  };

  return (
    <Modal visible={visible} setVisible={setVisible}>
      {plug && charger ? (
        <View style={styles.modalContainer}>
          <View style={styles.infoModalContent}>
            <Card
              elevation={0}
              style={[
                styles.infoModalCardIcon,
                {
                  backgroundColor: backgroundColors[plug.status],
                },
              ]}>
              <View style={styles.infoModalCardIconContainer}>
                <Icon
                  name={plugTypeIcons[plug.type]}
                  color={textColors[plug.status]}
                  size={72}
                />
              </View>
            </Card>
            <View style={styles.infoModalDataContainer}>
              <Title>
                {charger.name} - Plug {plug.id} -{' '}
                {plugTypeDescriptions[plug.type]}
              </Title>
              <Subheading>{plugStatus[plug.status]}</Subheading>
              {plug.power && plug.progress ? (
                <Subheading>
                  {plug.power} kW - {plug.progress} kW.h
                </Subheading>
              ) : null}
            </View>
          </View>
          {plug.status !== 'none' ? (
            <View style={styles.infoModalActions}>
              {plug.status === 'stopped' ? (
                <Button
                  mode="outlined"
                  style={[
                    styles.modalButton,
                    {borderColor: colors.buttonGreen},
                  ]}
                  icon="play"
                  color={colors.buttonGreen}
                  onPress={() => {}}>
                  Resume
                </Button>
              ) : plug.status === 'error' ? (
                <Button
                  mode="outlined"
                  style={[
                    styles.modalButton,
                    {borderColor: colors.buttonOrange},
                  ]}
                  icon="restart"
                  color={colors.buttonOrange}
                  onPress={() => {}}>
                  Retry
                </Button>
              ) : (
                <Button
                  mode="outlined"
                  style={[styles.modalButton, {borderColor: colors.buttonRed}]}
                  icon="stop"
                  color={colors.buttonRed}
                  onPress={() => {}}>
                  Stop
                </Button>
              )}
              <View style={styles.infoModalPowerSelector}>
                <IconButton icon="minus" onPress={() => {}} size={20} />
                <Text style={styles.infoModalPowerSelectorText}>33%</Text>
                <IconButton icon="plus" onPress={() => {}} size={20} />
              </View>
            </View>
          ) : null}
        </View>
      ) : (
        <Text>Error</Text>
      )}
    </Modal>
  );
};

export default InfoModal;
