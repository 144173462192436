import React from 'react';
import {
  Appearance,
  ColorSchemeName,
  StatusBar,
  useColorScheme,
} from 'react-native';
import {Provider as PaperProvider} from 'react-native-paper';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import Main from './src/scenes/Main';
import themes from './src/styles/colors';

const App = () => {
  const [colorScheme, setColorScheme] = React.useState<ColorSchemeName>(
    Appearance.getColorScheme(),
  );

  const handleAppearanceChange = (prefs: Appearance.AppearancePreferences) => {
    console.log('Changing color scheme');
    setColorScheme(Appearance.getColorScheme());
  };

  React.useEffect(() => {
    Appearance.addChangeListener(handleAppearanceChange);
    return () => {
      Appearance.removeChangeListener(handleAppearanceChange);
    };
  }, [null]);

  const theme = colorScheme === 'dark' ? themes.dark : themes.light;

  return (
    <SafeAreaProvider>
      <PaperProvider theme={theme.theme}>
        <StatusBar
          barStyle={theme.statusbar}
          backgroundColor={theme.theme.colors.background}
        />
        <Main />
      </PaperProvider>
    </SafeAreaProvider>
  );
};

export default App;
