import React from 'react';
import {Image, View} from 'react-native';
import {IconButton, Subheading, Title, useTheme} from 'react-native-paper';
import getStyles from '../styles/styles';
import {Charger} from '../ts/types';
import Plug from './Plug';

const AppHeader: React.FC = () => {
  const theme = useTheme();
  const styles = getStyles(theme as any);

  return (
    <View style={styles.appheaderContainer}>
      <Image
        source={require('../assets/nomadev_logo.png')}
        style={styles.appheaderLogo}
        resizeMode="contain"
      />
      <View style={styles.appheaderSettingsButton}>
        <IconButton icon="cog" size={28} onPress={() => {}} />
      </View>
    </View>
  );
};

export default AppHeader;
