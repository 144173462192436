import React from 'react';
import {FlatList, View} from 'react-native';
import {Text, Title, useTheme} from 'react-native-paper';
import AppHeader from '../components/AppHeader';
import ChargePoint from '../components/Chargepoint';
import {Charger} from '../ts/types';

import chargers from '../data/chargers';
import InfoModal from '../components/InfoModal';
import {Theme} from '../styles/colors';
import getStyles from '../styles/styles';
import AppFooter from '../components/AppFooter';
import AddModal from '../components/AddModal';
import EditModal from '../components/EditModal';

const Main = () => {
  const theme = useTheme() as Theme;
  const styles = getStyles(theme);

  const [infoModalVisible, setInfoModalVisible] = React.useState(false);
  const [addModalVisible, setAddModalVisible] = React.useState(false);
  const [editModalVisible, setEditModalVisible] = React.useState(false);
  const [selectedCharger, setSelectedCharger] = React.useState<number | null>(
    null,
  );
  const [selectedPlug, setSelectedPlug] = React.useState<number | null>(null);

  const selectPlug = (chargerId: number, plugId: number) => {
    setSelectedPlug(plugId);
    setSelectedCharger(chargerId);
    setInfoModalVisible(true);
  };

  const editCharger = (chargerId: number) => {
    setSelectedCharger(chargerId);
    setEditModalVisible(true);
  };

  return (
    <View style={styles.page}>
      <FlatList
        data={chargers}
        renderItem={({item}) => (
          <ChargePoint
            charger={item}
            selectPlug={selectPlug}
            editCharger={editCharger}
          />
        )}
        ListHeaderComponent={AppHeader}
        ListFooterComponent={AppFooter}
      />

      <InfoModal
        visible={infoModalVisible}
        setVisible={setInfoModalVisible}
        charger={chargers.find(c => c.id === selectedCharger) || null}
        plug={
          chargers
            .find(c => c.id === selectedCharger)
            ?.plugs.find(p => p.id === selectedPlug) || null
        }
      />
      <AddModal visible={addModalVisible} setVisible={setAddModalVisible} />
      <EditModal
        visible={editModalVisible}
        setVisible={setEditModalVisible}
        charger={chargers.find(c => c.id === selectedCharger) || null}
      />
    </View>
  );
};

export default Main;
