import React from 'react';
import {View} from 'react-native';
import {
  Card,
  Subheading,
  Title,
  Text,
  Button,
  useTheme,
  IconButton,
} from 'react-native-paper';
import getStyles from '../styles/styles';
import {Charger, Plug, ModalProps} from '../ts/types';
import Modal from './Modal';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {Theme} from '../styles/colors';

type Props = ModalProps;

const AddModal: React.FC<Props> = ({visible, setVisible}) => {
  const theme = useTheme() as Theme;
  const {colors} = theme;
  const styles = getStyles(theme);

  return <Modal visible={visible} setVisible={setVisible}></Modal>;
};

export default AddModal;
