import React from 'react';
import {View} from 'react-native';
import {IconButton, Subheading, Title, useTheme} from 'react-native-paper';
import {Theme} from '../styles/colors';
import getStyles from '../styles/styles';
import {Charger} from '../ts/types';
import Plug from './Plug';

type Props = {
  charger: Charger;
  selectPlug: (c: number, p: number) => void;
  editCharger: (c: number) => void;
};

const ChargePoint: React.FC<Props> = ({charger, selectPlug, editCharger}) => {
  const theme = useTheme() as Theme;
  const styles = getStyles(theme);

  return (
    <View style={styles.chargepointContainer}>
      <View style={styles.chargepointHeader}>
        <Title>{charger.name}</Title>
        <Subheading>
          {charger.model} - {charger.power}kW - {charger.phases} phase
          {charger.phases > 1 ? 's' : ''}
        </Subheading>
        <View style={styles.chargepointEditButton}>
          <IconButton
            icon="pencil"
            size={26}
            onPress={() => editCharger(charger.id)}
          />
        </View>
      </View>
      <View style={styles.chargepointBody}>
        {charger.plugs.map(p => (
          <Plug plug={p} charger={charger} key={p.id} select={selectPlug} />
        ))}
      </View>
    </View>
  );
};

export default ChargePoint;
