import {Dimensions, Platform, StyleSheet} from 'react-native';
import {Theme} from './colors';

const getStyles = (theme: Theme) => {
  const {colors} = theme;
  const {width} = Dimensions.get('window');

  return StyleSheet.create({
    page: {
      flex: 1,
      backgroundColor: colors.background,
      ...(width <= 800 || Platform.OS !== 'web'
        ? {}
        : {
            maxWidth: 800,
            minWidth: 800, // Sorry, havent found another way
            alignSelf: 'center',
          }),
    },
    container: {
      flex: 1,
      margin: 10,
    },
    chargepointHeader: {
      alignItems: 'center',
      flex: 1,
    },
    chargepointContainer: {
      flex: 1,
      margin: 10,
      marginTop: 30,
    },
    chargepointBody: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'stretch',
    },
    chargepointEditButton: {
      position: 'absolute',
      right: 0,
      top: 0,
    },
    plugContainer: {
      margin: 10,
      flex: 1,
    },
    plugCard: {
      flex: 1,
      minHeight: 150,
      borderRadius: 10,
    },
    plugCardRipple: {
      flex: 1,
    },
    plugCardBody: {
      alignItems: 'center',
      padding: 5,
    },
    plugStatusText: {
      fontSize: 18,
      fontWeight: 'bold',
    },
    plugPowerText: {
      fontSize: 17,
    },
    plugProgressText: {
      fontSize: 17,
    },
    bottomModal: {
      justifyContent: 'flex-end',
      margin: 0,
    },
    modalCard: {
      borderRadius: 0,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10,
      maxHeight: '90%',
    },
    appheaderContainer: {
      margin: 10,
      marginBottom: 40,
      alignItems: 'center',
    },
    appheaderLogo: {
      height: 50,
      width: 200,
    },
    appheaderSettingsButton: {
      position: 'absolute',
      right: 0,
      top: 0,
    },
    appfooterContainer: {
      margin: 20,
      marginTop: 80,
      flex: 1,
    },
    appfooterCard: {
      height: 120,
      borderRadius: 6,
      borderWidth: 0.2,
      borderColor: colors.text,
      backgroundColor: colors.background,
    },
    appfooterCardContainer: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    appfooterTitle: {
      color: colors.disabled,
      fontWeight: 'normal',
    },
    modalContainer: {
      margin: 10,
      flex: 1,
    },
    infoModalContent: {
      flex: 1,
      flexDirection: 'row',
    },
    infoModalCardIcon: {
      borderRadius: 12,
      height: 100,
      width: 100,
    },
    infoModalCardIconContainer: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    infoModalDataContainer: {
      marginLeft: 15,
    },
    infoModalActions: {
      marginTop: 15,
      flex: 1,
      flexDirection: 'row',
    },
    modalButton: {
      justifyContent: 'center',
      borderRadius: 18,
      height: 36,
    },
    infoModalPowerSelector: {
      marginLeft: 10,
      flexDirection: 'row',
      height: 36,
      borderRadius: 18,
      alignItems: 'center',
      borderWidth: 0.2,
      borderColor: colors.onSurface,
    },
    infoModalPowerSelectorText: {},
    editModalActions: {
      marginTop: 15,
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  });
};

export default getStyles;
